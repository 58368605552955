import React, { useState } from 'react'
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Image,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'

const Show: React.FC = () => {
  const [auth] = useRecoilState(authState)
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [media, setMedia] = useState<{
    type: 'video' | 'image' | 'embed' | 'pdf' | 'docx' | 'redirect'
    src: string
  } | null>(null)

  const handleOpenDialog = (
    type: 'video' | 'image' | 'embed' | 'pdf' | 'docx' | 'redirect',
    src: string
  ) => {
    if (type === 'pdf' || type === 'docx' || type === 'redirect') {
      // Redirect or download for pdf, docx, and redirect types
      window.location.href = src
    } else {
      setMedia({ type, src })
      onOpen()
    }
  }

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box mt={20}>
          <VStack spacing={4}>
            <Button onClick={() => handleOpenDialog('video', '/media/a.mp4')}>
              동영상설명 1
            </Button>
            <Button onClick={() => handleOpenDialog('video', '/media/b.mp4')}>
              동영상설명 2
            </Button>
            <Button onClick={() => handleOpenDialog('video', '/media/c.mp4')}>
              동영상설명 3
            </Button>
            <Button onClick={() => handleOpenDialog('image', '/media/m.jpeg')}>
              사진설명
            </Button>
            <Button
              onClick={() =>
                handleOpenDialog(
                  'embed',
                  'https://www.youtube.com/embed/7L7PyJjF1UE'
                )
              }
            >
              동영상설명 4
            </Button>
            <Button onClick={() => handleOpenDialog('video', '/media/d.mp4')}>
              동영상설명 5
            </Button>
            <Button onClick={() => handleOpenDialog('video', '/media/e.mp4')}>
              동영상설명 6
            </Button>
            <Button onClick={() => handleOpenDialog('pdf', '/media/001.pdf')}>
              PDF 파일 1
            </Button>
            <Button
              onClick={() => handleOpenDialog('image', '/media/002.jpeg')}
            >
              사진 파일
            </Button>
            <Button onClick={() => handleOpenDialog('pdf', '/media/003.pdf')}>
              PDF 파일 2
            </Button>
            <Button onClick={() => handleOpenDialog('docx', '/media/004.docx')}>
              DOCX 파일
            </Button>
            <Button
              onClick={() =>
                handleOpenDialog(
                  'redirect',
                  'http://www.bosdaily.kr/news/322579'
                )
              }
            >
              링크 1 (리다이렉트)
            </Button>
            <Button
              onClick={() =>
                handleOpenDialog('redirect', 'https://naver.me/5pwZTLDC')
              }
            >
              링크 2 (리다이렉트)
            </Button>
            <Button
              onClick={() =>
                handleOpenDialog(
                  'redirect',
                  'https://www.facebook.com/share/v/15dNnnmTDS/'
                )
              }
            >
              링크 3 (리다이렉트)
            </Button>
            <Button
              onClick={() =>
                handleOpenDialog(
                  'redirect',
                  'https://www.youtube.com/watch?v=jA-PiF9cQxA'
                )
              }
            >
              링크 4 (리다이렉트)
            </Button>
          </VStack>

          <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {media?.type === 'video'
                  ? '동영상'
                  : media?.type === 'image'
                  ? '사진'
                  : 'YouTube'}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {media?.type === 'video' ? (
                  <video controls width="100%">
                    <source src={media.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : media?.type === 'image' ? (
                  <Image src={media?.src} alt="media" width="100%" />
                ) : (
                  <iframe
                    width="100%"
                    height="400"
                    src={media?.src}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
              </ModalBody>
              <ModalFooter>
                <Button onClick={onClose}>닫기</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </DefaultLayout>
    </>
  )
}

export default Show
